import React from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';

const Contact = () => {
  return (
    <section id="contact">
      <Container>
        <Title title="TECHNOLOGIES" />
        <Fade bottom duration={1000} delay={900} distance="30px">
          <div className="contact-wrapper">
            <div className="container">
              <div className="row row-cols-2">
                <div className="col" style={{ marginBottom: '50px' }}>
                  <i className="fa fa-laptop fa-4x" />
                  <p className="contact-wrapper__text">Frontend</p>
                  <p>JavaScript, TypeScript, React, React Native, Redux, </p>
                  <p> Bootstrap, HTML, CSS, SCSS</p>
                </div>

                <div className="col" style={{ marginBottom: '50px' }}>
                  <i className="fa fa-code fa-4x" />
                  <p className="contact-wrapper__text">Backend</p>
                  <p>Node.js, Python, Ruby on Rails, Express, PostgreSQL, Sequelize</p>
                  <p>GraphQL, MongoDB</p>
                </div>

                <div className="col">
                  <i className="fa fa-paper-plane fa-4x" />
                  <p className="contact-wrapper__text">DevOps </p>
                  <p>Heroku, TravisCI, Git, GitHub</p>
                </div>

                <div className="col">
                  <i className="fa fa-flag fa-4x" />
                  <p className="contact-wrapper__text">Other Tools</p>
                  <p>SocketIO, Google APIs, Webpack, Babel, Postman, Postico,</p>
                  <p>Google Cloud Platform, Jasmin, Mocha, Chai, Jest, Gatsby, Go</p>
                </div>
              </div>
            </div>
            {/* </ul> */}
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
