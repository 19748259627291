import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
// import GithubButtons from '../GithubButtons/GithubButtons';

// import { githubButtons } from '../../mock/data';

const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;
  // const { isEnabled } = githubButtons;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || 'https://github.com/cobidev/gatsby-simplefolio'}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <hr />
        <div className="footer__text">
          {/* © {new Date().getFullYear()} - Template developed by{' '}
          <a href="https://github.com/cobidev" target="_blank" rel="noopener noreferrer">
            Jacobo Martínez
          </a> */}
          <h6>Photo Credit</h6>
          <h6>
            Manhattan photo by{' '}
            <a href="https://www.pexels.com/@charles-parker" rel="noreferrer" target="_blank">
              Charles Parker
            </a>{' '}
            from{' '}
            <a href="https://www.pexels.com/" rel="noreferrer" target="_blank">
              Pexels
            </a>
          </h6>
          <h6>
            Game photo Photo by{' '}
            <a
              href="https://unsplash.com/@carltraw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
              rel="noreferrer"
              target="_blank"
            >
              Carl Raw
            </a>{' '}
            on{' '}
            <a
              href="https://unsplash.com/s/photos/game?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
              rel="noreferrer"
              target="_blank"
            >
              Unsplash
            </a>
          </h6>
        </div>

        {/* {isEnabled && <GithubButtons />} */}
      </Container>
    </footer>
  );
};

export default Footer;
