import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about, contact } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;
  const { email } = contact;
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <img src="https://i.ibb.co/b1K76nH/Selina-circle-cropped-2.png" alt="profile" />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={900} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    'Prior to joining the Grace Hopper Program to become a software developer, I was working on social justice campaigns to empower those who are affected by systemic injustice. Throughout my career, I had the opportunity to collaborate with software developers to identify vulnerable populations and develop online tools to help these communities. Working alongside these individuals, I realized the far-reaching impact that technology has on our world, even in human rights.'}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo ||
                    'Looking forward, I am excited to branch out and explore all areas of software development. I am particularly intrigued by data visualization, animation, and AI since it grants me the opportunity to get hands on with the accessibility component of the user experience. I am excited that whatever I work on - whether it’s a platform or an app, in any industry, I will be able to deliver results in a lens of making an impact. '}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree || '👀 I’m interested in AI and ML'}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree || '🌱 I am currently learning Python and Typescript'}
                </p>

                <p className="about-wrapper__info-text">
                  {paragraphThree ||
                    '💪 I am fluent in JavaScript [ES6] | React | React Native | Redux | Node.js | Express | Postgres | Sequelize | HTML | CSS | Socket.IO | Git'}
                </p>

                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={`mailto:${email}`}
                    >
                      Contact Selina
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
